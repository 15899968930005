<template>
	<b-container v-if="$checkPermission('list-account')" fluid id="account">
		<cg-modal :data="data" @update="updateAccount" @change-password="confirmResetPassword"></cg-modal>

		<!-- Account confirmation modal -->
		<b-modal id="account-delete-confirm"  content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
			bodyClass="text-center"  @show="userHasConfirmed = ''" footerClass='p-2' :centered='true' 
			:okDisabled="userHasConfirmed != 'OK'" :title="$t('Account.ConfirmDeleteTitle')" @ok="deleteAccount">
			
			<p class="my-2 text-left">{{ $t('Account.ConfirmDeleteMessage', { param: selectedAccount.displayname }) }}</p>
			<p class="my-2 text-left" v-html="$t('General.ConfirmNotReversible')"></p>
			<input class="text-center mt-4" v-model="userHasConfirmed" />
		</b-modal>

		<!-- Modal for unsaved info -->
		<b-modal id="unsaved-account-info" content-class="shadow" size='md' buttonSize='md' okVariant='danger'
			bodyClass="text-center" footerClass="p-2" :centered='true' @ok="undoUnsavedChanges" :title="$t('General.UnsavedInfo')">
			<p class="my-2 text-left">{{ $t('General.UnsavedInfoText') }}</p>
		</b-modal>

		<!-- Account Roles management modal -->
		<b-modal id="manage-roles-modal" content-class="shadow" size='lg' @hide="manageUnsavedInfo" hide-footer
			:centered='true' ref="cgRoleModal" :title="manageRolesModalTitle">

			<b-table :fields="fieldsACR" sticky-header fixed :items="accountACR" tbody-tr-class="row-height">
				
				<template #head()="data">
					<div :id="data.label" class="ellipsable-text">
						<b-tooltip :target="data.label" :title="data.label" triggers="hover">
							<span><b>{{ data.label }}</b></span>
						</b-tooltip>

						<span>{{ data.label }}</span>
					</div>
				</template>

				<template #cell(company_label)="data">
					<b-tooltip :target="data.value" :title="data.value" triggers="hover">
						<span><b>{{ data.value }}</b></span>
					</b-tooltip>

					<span :id="data.value">{{ data.value }}</span>
				</template>
				<template #cell()="data">
					<div :class="[data.value == 1 ? 'assigned' : '']" class="text-center" @click="toggleRoles(data)">
						<span>
							<font-awesome-icon v-if="data.value == 1" :icon="['fas', 'check']" class="white-icon"/>
							<font-awesome-icon v-else :icon="['fas', 'times']" />
						</span>
					</div>
				</template>	
			</b-table>
			
			<b-row align-h="end" class="cg-modal-footer mt-4 pt-3 mb-1">
				<b-col cols="auto">
					<b-button @click="manageUnsavedInfo">{{ $t('General.Cancel') }}</b-button>
					<b-button @click.prevent="updateAccountRoles" variant="primary">{{ $t('General.Save') }}</b-button>
				</b-col>
			</b-row>
		</b-modal>

		<cg-loader :display="inProgress"></cg-loader>

		<cg-localized-alert :alertVariant="alertVariant" ref="cgLocAlert" :alertMessage="alertMessage"
			:alertParams="alertParams" :dismissSecs="dismissSecs"></cg-localized-alert>

		<div class="px-4 mb-4">
			<b-card-title class="mb-4">{{ $t('Account.Title') }}</b-card-title>

			<!-- New Account button -->
			<b-row align-h="end">
				<b-col cols="auto">
					<b-button v-if="$checkPermission('create-account')" @click="addNewAccount()" size="sm" variant="primary" class="mb-3">
						<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'plus']"/>{{ $t('Account.NewAccount') }}
					</b-button>
				</b-col>
			</b-row>

			<!-- Account search -->
			<b-row align-h="end" class="mb-4">
				<b-col lg="8" md="12">
					<b-form-group :label="$t('General.Search')" label-cols-sm="3" label-cols-md="4" label-cols-xl="5" label-align-sm="right" label-size="sm" 
						label-for="filterInput" class="mb-0"><b-input-group size="sm">
							<b-form-input v-model="filter" type="search" debounce="500" id="filterInput" 
								:placeholder="$t('General.TypeToSearch')"></b-form-input>
							<template v-slot:append>
								<b-input-group-text class="icon-append">
									<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'search']"/>
								</b-input-group-text>
							</template>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>

			<!-- Account table -->
			<b-table
				id="accountListTable"
				ref="accountListTable"
				:items="getCurrentAccountPage"
				:per-page="perPage"
				:current-page="currentPage"
				:fields="fields"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:filter="filter"
				hover striped show-empty bordered no-sort-reset
				:emptyFilteredText="$t('General.TableFilterEmpty')"
				:empty-text="$t('General.TableEmpty')">
				
				<!-- Additional template info for Company name list -->
				<template v-slot:cell(company_label)="data">
					<span>{{ data.item.company_label }}</span>
				</template>
				<!-- Additional template info for action column -->
				<template v-slot:cell(actions)="row">
					<div class="table-row-center">
						<div v-if="$checkPermission('delete-account')" class="action delete">
							<font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
								@click="confirmDeleteAccount(row.index, row.item)"/>
						</div>
						<div v-if="$checkPermission('modify-account')" class="action edit">
							<font-awesome-icon class="mx-2" :icon="['fas', 'pencil-alt']"
								@click="editAccount(row.index, row.item)"/>
						</div>
						<div v-if="$checkPermission('modify-account')" class="action roles">
							<font-awesome-icon class="mx-2" :icon="['fas', 'user-shield']"
								@click="showManageRolesModal(row.index, row.item)"/>
						</div>
					</div>
				</template>
			</b-table>

			<!-- Pagination widget -->
			<b-row align-h='end'>
				<b-col class="mb-2 d-block d-sm-block d-md-none">
					<label for="itemsPerPageSmall" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
					<b-form-select id="itemsPerPageSmall" class="float-right" v-model="perPage" :options="pageSizeOptions"></b-form-select>
				</b-col>
				<b-col class="text-right pt-1 pr-0 d-none d-sm-none d-md-block">
					<label for="itemsPerPage" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
				</b-col>
				<b-col cols="12" md="auto" class="mb-2 pr-0 d-none d-sm-none d-md-block">
					<!-- Items per Page widget -->
					<b-form-select id="itemsPerPage" class="float-right" v-model="perPage" :options="pageSizeOptions"></b-form-select>
				</b-col>

				<b-col cols="12" md="auto">
					<!-- Pagination widget -->
					<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
						aria-controls="accountListTable" :disabled="perPage<1"></b-pagination>
				</b-col>
			</b-row>
			
		</div>
	</b-container>
</template>

<script>
import accountService from "@/services/account.service.js";
import syspropService from "@/services/sysprop.service.js";
import { TablePaginationSize } from "../../../server/common/constants";
import { isEmail } from 'validator';
import Vue from 'vue';

export default {
	name: 'cg-accounts',
		data: function () {
		return {
			filter: '',
			perPage: 15,
			currentPage: 1,
			totalRows: 1,
			alertMessage: '',
			alertVariant: 'danger',
			alertParams: {},
			alertCount: 0,
			dismissSecs: 5,
			inProgress: false,
			appLangList: [],
			sortBy: "displayname",
			userHasConfirmed: '',
			sortDesc: false,
			fields: [],
			contactEmail: '',
			selectedAccount: {},
			accountCompanyList: null,
			accountValidDomains: null,
			untouchEmailDomain: null,
			accountAssignedRoles: null,
			manageRolesModalTitle: '',
			ldapEnabled: null,
			data: {
				name: "account-modal",
				title: 'Account.NewAccount',
				label: 'Account.NewAccount',
				item: {},
				submitHandler: "updateAccount",
				customEvent: {
					permission: this.$checkPermission('change-password'),
					label: 'Account.ResetPwdTitle',
					handler: 'change-password',
					disabled: false
				},
				fields:[
					{   id: "displayname",
						label: 'Account.DisplayName',
						type: "text",
						display: true,
						required: true,
						placeholder: 'Account.DisplayName'
					},
					{   id: "email",
						label: 'Account.Email',
						type: "email",
						display: true,
						required: true,
						disabled: false,
						placeholder: 'Account.Email'
					},
					{   id: "language",
						label: 'General.Language',
						type: "select",
						display: true,
						required: true,
						options: [],
						placeholder: 'General.Language'
					},
					{   id: "ldap_authentication",
						label: 'Account.AuthenticationLDAP',
						type: "simple-checkbox",
						disabled: false,
						display: true,
						value: 1,   
						unchecked_value: 0,
						change: this.disableResetButton
					},
					{   id: "company_list",
						label: 'Account.Company',
						disabled: true,
						type: "checkbox",
						display: true,
						required: false,
						dataset: [],
						key: "company_id",
						emptyDatasetMessage: 'Account.NoCompanies',
						placeholder: 'Account.Company'
					},
					{   id: "email_domain",
						label: 'Company.EmailDomain',
						type: "textarea",
						rows: 3,
						lineheight: 4,
						display: true,
						required: false,
						disabled: false,
						placeholder: '@company_a.com, @company_b.com',
						validator: this.isValidDomainSet,
						errorAlertMessage: '',
						errorAlertParams: this.getAlertParams,
						errorAlertCount: this.getAlertCount
					},
					{   id: "password",
						label: 'Account.Password',
						type: "password",
						display: true,
						disabled: false,
						required: true,
						validator: this.passwordStrength,
						placeholder: 'Account.Password'
					}
				]	
			},
			fieldsACR: [],
			accountACR: [],
			hasEditedRoles: null
		}
	},
	computed: {
		pageSizeOptions() {
			return TablePaginationSize || [];
		}
	},
	methods: {
		disableResetButton(val) {
			Vue.set(this.data.customEvent, 'disabled', !!val);
			Vue.set(this.data.fields.find(i => i.id === 'password'), 'required', !val);
			Vue.set(this.data.fields.find(i => i.id === 'password'), 'disabled', !!val);
		},
		updateAccount(newAccount) {
			console.debug("Account - updateAccount", newAccount);
			this.inProgress = true;

			let accountPromise;

			if(this.ldapEnabled == 2 || this.ldapEnabled == 0) {
				delete newAccount.ldap_authentication;
			}

			if(this.untouchEmailDomain && this.untouchEmailDomain.length) {
				newAccount.email_domain = this.untouchEmailDomain.join(', ') + ', ' + newAccount.email_domain;
			}

			if (newAccount.account_id) {
				accountPromise = accountService.updateAccount(newAccount);
			} else {
				newAccount.password = newAccount.ldap_authentication ? 'ldap_authenticated' : newAccount.password;
				accountPromise = accountService.createAccount(newAccount);
			}

			accountPromise.then((res) => {
				console.debug("Accounts - updateAccount success", res);
				this.alertVariant = 'success';
				this.alertMessage = newAccount.account_id ? 'Account.AccountModified' : 'Account.AccountCreated';
				
				// Refresh table to reload data
			}).catch((error) => {
				console.error("Accounts - updateAccount error", error);
				if (error.response.status == 409) {
					this.alertMessage = 'Account.AccountAlreadyExists';
					this.alertParams = { param: newAccount.email };
					this.dismissSecs = 30;
				} else if (error.response.status == 401) {
					this.alertMessage = 'Account.AccountManagementError';
				} else if (error.response.status == 404) {
					this.alertMessage = 'Account.UpdateAccountRolesNotFound';
				} else {
					this.alertMessage = 'Account.GenericError';
				}
				this.alertVariant = 'danger';
			}).finally(() => {
				this.$nextTick(() => {
					this.$refs.accountListTable.refresh();
				});
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		addNewAccount() {
			console.debug("Accounts - addNewAccount");
			this.data.item = { displayname: '', email: '', password: '', creator_id: '', language: null };

			this.data.title = 'Account.NewAccount';
			this.data.label = 'Account.NewAccount';
			
			this.data.item.creator_id= this.$account.account_id;
			this.data.item.ldap_authentication = this.ldapEnabled == 2 ? 1 : 0;

			Vue.set(this.data.fields.find(i => i.id === 'company_list'), 'display', false);
			Vue.set(this.data.fields.find(i => i.id === 'email_domain'), 'disabled', false);
			Vue.set(this.data.fields.find(i => i.id === 'email'), 'disabled', false);
			Vue.set(this.data.fields.find(i => i.id === 'password'), 'display', true);
			Vue.set(this.data.fields.find(i => i.id === 'ldap_authentication'), 'disabled', this.ldapEnabled == 2);
			Vue.set(this.data.fields.find(i => i.id === 'ldap_authentication'), 'display', this.ldapEnabled);
			Vue.set(this.data.fields.find(i => i.id === 'password'), 'required', this.ldapEnabled != 2);
			Vue.set(this.data.fields.find(i => i.id === 'password'), 'disabled', this.ldapEnabled == 2);

			this.data.customEvent.permission = false;
			
			this.$bvModal.show("account-modal");
		},
		toggleRoles(data) {
			console.debug("Accounts - toggleRoles", data);

			const role = data.field.key;

			this.accountAssignedRoles.forEach((r) => {
				if('{'+r.role_id+'$_$'+r.name+'}' === role) {
					Vue.set(this.accountACR[data.index], '{'+r.role_id+'$_$'+r.name+'}', data.value? 0 : 1);
					this.hasEditedRoles = true;
				}
			});
		},
		undoUnsavedChanges() {
			this.hasEditedRoles = false;
			this.$refs.cgRoleModal.hide();
		},
		manageUnsavedInfo(bvModalEvt) {
			if(this.hasEditedRoles) {
				// Prevent modal from closing
				bvModalEvt.preventDefault();
				// Trigger submit handler
				this.$bvModal.show('unsaved-account-info');
			} else {
				this.$refs.cgRoleModal.hide();
			}
		},
		async showManageRolesModal(index, record) {
			console.debug("Accounts - showManageRolesModal", index, record);

			this.selectedAccount = record;
			this.manageRolesModalTitle = this.$t('Account.EditAccountRoles', { param: this.selectedAccount.displayname });

			// Empty CompanyRole matrix
			let ACRMatrix = {};
			this.accountACR = [];

			this.inProgress = true;

			// For each Company assigned to current logged Account, represented on rows
			let companies = this.accountCompanyList.map((comp) => comp.company_id);
			for(let c of companies) {

				// Retrieve Role assignment to selected Account, by cycling on Roles assigned to current logged Account, represented on rows
				let a_r = await accountService.getAccountRoles(this.selectedAccount.account_id, c);
				let roles = a_r.data.map((role) => role.role_id);
				Vue.set(ACRMatrix, c, []);
				roles.forEach((r) => {
					Vue.set(ACRMatrix[c], r, 1);
				});
			}

			// Now fill accountACR
			this.accountCompanyList.forEach((c) => {
				let acr = {};
				this.accountAssignedRoles.forEach((r) => {
					acr['{'+r.role_id+'$_$'+r.name+'}'] = ACRMatrix[c.company_id][r.role_id]? 1 : 0;
					acr.company_label = c.name;
					acr.company_id =  c.company_id;
				});
				this.accountACR.push(acr);
			});

			this.inProgress = false;

			this.$bvModal.show("manage-roles-modal");
		},
		async updateAccountRoles() {
			console.debug("Accounts - updateAccountRoles");

			let acr = [];

			this.accountACR.forEach((company) => {
				let roles = [];

				// Since roles are in the form { 1$_$Admin }, extract keys that matches roles
				let keys = Object.keys(company).filter((k) => { return k.match(/{(\d+)\$_\$(.*?)}/g); });
				
				keys.forEach((k) => {
					let roleRE = /{(\d+)\$_\$(.*?)}/g;
					// For each key, retrieve role_id in group 1
					let role_details = roleRE.exec(k);
					if(company[k] == 1 && role_details && role_details[1]) {
						roles.push(role_details[1]);
					}
				});

				if(roles && roles.length > 0) {
					acr.push({ 'company_id': company.company_id, 'roles': roles });
				}
			});
	
			this.inProgress = true;
			let data = {
				company_list: this.accountCompanyList.map((c) => c.company_id),
				role_list: this.accountAssignedRoles.map((r) => r.role_id),
				acr_matrix: acr
			}

			accountService.updateAccountRoles(this.selectedAccount.account_id, data).then(() => {
				console.debug("Accounts - updateAccountRoles success");
				this.alertMessage = 'Account.UpdateAccountRolesSuccess';
				this.alertVariant = 'success';
			}).catch(err => {
				console.error("Accounts - updateAccountRoles error", err);
				if(err.response.status == 404) {
					this.alertMessage = 'Account.UpdateAccountRolesNotFound';
				} else {
					this.alertMessage = 'Account.UpdateAccountRolesError';
				}
				this.alertVariant = 'danger';
			}).finally(() => {
				this.hasEditedRoles = false;
				this.manageUnsavedInfo();

				// Refresh table to reload data
				this.$nextTick(() => {
					this.$refs.accountListTable.refresh();
				});
				this.$refs.cgLocAlert.showAlert();
				this.selectedAccount = {};
				this.inProgress = false;
			});
		},
		async editAccount(index, record) {
			console.debug("Accounts - editAccount", index, record);

			this.data.title = 'Account.EditAccount';
			this.data.label = 'General.Save';
			this.data.item = record;
			
			// if ldap enable in config is "forced", force toggle to value 1
			this.data.item.ldap_authentication = this.ldapEnabled == 2 ? 1 : this.data.item.ldap_authentication;
			let disableEmailDomain = !this.$checkPermission('list-company-all') && this.data.item.account_id == this.$account.account_id;

			if(this.data.item.email_domain && !this.$checkPermission('list-company-all')) {
				let accountEmailDomain = this.data.item.email_domain.split(/,| /).filter( dom => { return  dom != ''; } );
				this.untouchEmailDomain = accountEmailDomain && accountEmailDomain.filter( dom => { return !this.accountValidDomains.includes(dom.trim()); } );
				this.data.item.email_domain = accountEmailDomain && accountEmailDomain.filter(el => !this.untouchEmailDomain.includes(el)).join(', ');
			}
			
			Vue.set(this.data.fields.find(i => i.id === 'email'), 'disabled', true);
			Vue.set(this.data.fields.find(i => i.id === 'email_domain'), 'disabled', disableEmailDomain);
			Vue.set(this.data.fields.find(i => i.id === 'company_list'), 'display', true);
			Vue.set(this.data.fields.find(i => i.id === 'company_list'), 'dataset', this.data.item['company_list']);
			Vue.set(this.data.fields.find(i => i.id === 'password'), 'required', false);
			Vue.set(this.data.fields.find(i => i.id === 'password'), 'display', false);
			Vue.set(this.data.fields.find(i => i.id === 'ldap_authentication'), 'disabled', this.ldapEnabled == 2);
			Vue.set(this.data.fields.find(i => i.id === 'ldap_authentication'), 'display', this.ldapEnabled);

			this.data.customEvent.disabled = this.ldapEnabled == 2 || this.data.item.ldap_authentication == 1;
			this.data.customEvent.permission = this.$checkPermission('change-password');

			this.$bvModal.show("account-modal");
		},
		isValidDomainSet(value) {
			if(!value) {
				return;
			}

			// Super Admin shall only insert valid email addresses
			let domains = value.split(/,| /).filter( dom => { return  dom != ''; } );
			this.invalidDomain = domains.filter( dom => { return !isEmail('test' + dom.trim()); } );

			if(this.invalidDomain.length > 0) {
				Vue.set(this.data.fields.find(i => i.id === 'email_domain'), 'errorAlertMessage', 'Company.InvalidEmailDomainValue');
				return false;
			}

			if(this.$checkPermission('list-company-all')) {
				return true;
			}

			// Other Account role must insert allowed email domains only
			this.invalidAccountDomain = domains.filter( dom => { return !this.accountValidDomains.includes(dom.trim()); } );

			if(this.invalidAccountDomain.length > 0) {
				Vue.set(this.data.fields.find(i => i.id === 'email_domain'), 'errorAlertMessage', 'Account.InvalidEmailDomain');
				return false;
			}

			return true;
		},
		getAlertParams() {
			return this.invalidDomain.length > 0 ? { domain: this.invalidDomain.join(', ') } : 
					this.invalidAccountDomain.length > 0 ? { domain: this.$account.email_domain } : {};
		},
		getAlertCount() {
			return this.invalidDomain.length > 0 ? this.invalidDomain.length : this.accountValidDomains.length;
		},
		async resetPassword(account) {
			let emailLanguage = account && account.language? account.language : this.$i18n.locale;
			let support = this.contactEmail? 
				this.$i18n.t('Account.ResetPwdContact', emailLanguage, { support: this.contactEmail }) : 
				this.$i18n.t('Account.ResetPwdSupport', emailLanguage);

			let data = { 
				email: account.email, 
				subject: this.$t('Account.ResetPwdSubject', emailLanguage),
				body: this.$i18n.t('Account.ResetPwdBody', emailLanguage, { displayname: account.displayname, email: account.email, password: '{{.Password}}', support: support })
			}
			this.inProgress = true;

			accountService.resetPassword(account.account_id, data).then(() => {
                console.debug("Accounts - resetPassword success");
				this.alertMessage = 'Account.ResetPwdSuccess';
				this.alertParams = { param: account.displayname };
                this.alertVariant = 'success';
            }).catch(err => {
                console.error("Accounts - resetPassword error", err);
				this.alertMessage = 'Account.ResetPwdError';
				this.alertParams = { param: account.displayname };
                this.alertVariant = 'danger';
			}).finally(() => {
				this.$refs.cgLocAlert.showAlert();
				this.inProgress = false;
			});
		},
		confirmResetPwdTitle() {
			return this.$i18n.t("Account.ResetPwdTitle");
		},
		confirmResetPwdMessage(account) {
			return this.$i18n.t("Account.ResetPwdMessage", { param: account.displayname });
		},
		confirmResetPassword(account) {
			// This function displays a modal for the user to confirm the reset request
			this.$bvModal.msgBoxConfirm(this.confirmResetPwdMessage(account), {
				title: this.confirmResetPwdTitle(),
				size: 'md',
				buttonSize: 'md',
				okVariant: 'darknavy',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				// If the Admin wants to proceed, reset the password for the current user
				if (value) {
					this.resetPassword(account);
				}
			}).catch(err => {
				// The user canceled the action
				console.error("Accounts - confirmResetPassword error", err);
			});
		},
		confirmDeleteAccount(index, item) {
			console.debug("Accounts - confirmDeleteAccount - opening confirmation modal for", index, item);
			
			this.selectedAccount = item;
			this.$bvModal.show("account-delete-confirm");
		},
		deleteAccount() {
			this.inProgress = true;

			accountService.deleteAccount(this.selectedAccount.account_id).then(() => {
				console.debug("Accounts - deleteAccount success");
					
				this.alertVariant = 'success';
				this.alertMessage = 'Account.AccountDeleted';

				// Refresh table to reload data
			}).catch((error) => {
				console.error("Accounts - deleteAccount error", error);
				if(error.response.status == 404) {
					this.alertMessage = 'Account.UpdateAccountRolesNotFound';
				} else {
					this.alertMessage = 'Account.AccountDeleteError';
				}
				this.alertVariant = 'danger';
			}).finally(() => {
				this.$nextTick(() => {
					this.$refs.accountListTable.refresh();
				});	
				this.inProgress = false;
				this.selectedAccount = {};
				this.$refs.cgLocAlert.showAlert();
			});
		},
		passwordStrength(value) {
			return value.length == 0 ? null : value.length > 1 ? true : false;
		},
		getCurrentAccountPage(ctx) {
			const promise = accountService.getAccounts(this.$account.account_id, ctx.filter, ctx.currentPage, ctx.perPage, ctx.sortBy, ctx.sortDesc);
			
			return promise.then(result => {
				result.data.accounts.forEach((account) => {
					if (account.company_label) {
						account.company_list = [];
						let companies = account.company_label.split(",");
						companies.forEach((comp, index) => {
							account.company_list.push({ company_id: index, name: comp });
						});
						account.company_label = account.company_label.replace(/(\s*)(,\s*)/mg, ", ");
					}
				});
				const items = result.data.accounts;
				this.totalRows = result.data.count;
				return items || [];
			});
		}
	},
	async created() {

		this.fields = [
			{ key: 'displayname', label: this.$t('General.DisplayName'),  sortable: true },
			{ key: 'email', label: this.$t('General.Email'), sortable: true },
			{ key: 'company_label', label: this.$t('Account.Company'),  sortable: true, class: "text-ellipsis" }
		];

		const config = await syspropService.getConfig();
		this.contactEmail = config.data.defaults.contact;
		switch(config.data.ldap.enabled) {
			case 'forced':
				this.ldapEnabled = 2;
				break;
			case 'true':
				this.ldapEnabled = 1;
				break;
			default:
				this.ldapEnabled = 0;
		}

		var appLanguages = await syspropService.getLanguages();
		appLanguages.data.map((lan) => { return this.appLangList.push({ value: lan.language_id, text: lan.name}) });

		this.appLangList.unshift({ value: null, text: this.$i18n.t('General.SelectOption') });
		Vue.set(this.data.fields.find(i => i.id === 'language'), 'options', this.appLangList);

		if(this.$checkPermission('modify-account') || this.$checkPermission('delete-account')) {
			this.fields.push({ key: "actions", label: this.$t('General.Actions')});
		}

		this.accountCompanyList = this.$account.companies.sort((a, b) => { return ('' + a.name).localeCompare(b.name); });
		this.accountValidDomains = this.$account.email_domain && this.$account.email_domain.split(/,| /).filter( dom => { return  dom != ''; } );

		this.fieldsACR = [];

		if (this.$checkPermission('list-company-all')) {
			await syspropService.getAllRoles().then((res) => {
				this.accountAssignedRoles = res.data.sort((a, b) => { return ('' + a.name).localeCompare(b.name); });
			});
		} else {
			await accountService.getAccountRoles(this.$account.account_id).then((res) => {
				this.accountAssignedRoles = res.data.sort((a, b) => { return ('' + a.name).localeCompare(b.name); });
			});
		}

		this.fieldsACR = [
			{ key: 'company_label', label: '', sortable: false, thClass: 'acrmatrix-header-col acrmatrix-role-name', tdClass: 'acrmatrix-company col px-4 py-0' }
		];
		this.accountAssignedRoles.forEach((r) => {
			this.fieldsACR.push({ key: '{'+r.role_id+'$_$'+r.name+'}', label: r.name, sortable: false, thClass: 'acrmatrix-header-col acrmatrix-role-name', tdClass: 'px-0 py-0 lh-35' });
		});
		
		// console.debug("Logged account ACR matrix visibility", this.accountCompanyList, this.accountAssignedRoles);
	}
}
</script>
 
<style lang="less">
.table .text-ellipsis {
	position: relative;
}
.table .text-ellipsis span {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	position: absolute;
	width: 96%;
}
.text-ellipsis:before {
	content: '';
	display: inline-block;
}
.lh-35 {
	line-height: 35px;
}
.ellipsable-text {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
.tooltip.b-tooltip {
	opacity: 1;
}
.tooltip-inner {
	min-width: 100px;
	max-width: 300px;
	background-color: white;
	color: #2c3e50;
	font-size: 12px;
	border: 1px solid #2c3e50;
	font-family: "Avenir", Helvetica, Arial, sans-serif;
}
.bs-tooltip-top .arrow::before {
	border-top-color: #2c3e50;
	border-width: 0.2rem 0.2rem 0;
}
#manage-roles-modal {
	.modal-dialog {
		min-width: calc(100% - 3.5rem);
		.modal-body {
			overflow-y: auto;
			margin: 16px 0 0;
		}
	}
	& .cg-modal-footer {
		border-top: @cg-border-light;
		button:last-of-type {
			margin: 0 0 0 20px;
		}
		button:only-of-type {
			margin: 0;
		}
	}
}
.b-table-sticky-header {
    max-height: 500px;
}
.row-height {
	line-height: 35px;
}
.white-icon {
	color: white;
}
.acrmatrix-company {
	line-height: 35px; 
	height: 35px; 
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow: ellipsis;
	text-align: end;
}
.acrmatrix-header-col {
    text-align: center;
	font-weight: 400;
}
.acrmatrix-role-name {
	white-space: nowrap; 
	font-size: 12px; 
	overflow: hidden; 
	text-overflow: ellipsis;
}
.assigned {
	background-color: teal;
}
</style>